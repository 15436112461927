define('ember-print-this/services/print-this', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    print: function print(printSelector, options) {
      var jQuery = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Ember.$;

      var environment = Ember.getOwner(this).resolveRegistration('config:environment');
      var mergedOptions = this._constructPrintOptions(environment, options);

      this._selectElement(printSelector, jQuery).printThis(mergedOptions);
    },
    _constructPrintOptions: function _constructPrintOptions() {
      var environment = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { rootURL: '/' };
      var userOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var base = environment.rootURL || environment.baseURL;
      var options = base === '/' ? {} : { base: base };

      return Ember.merge(options, userOptions);
    },


    _selectElement: function _selectElement(toSelect, jQuery) {
      return jQuery(toSelect);
    }
  });
});