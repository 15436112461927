define("ember-cli-chart/components/ember-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "canvas",
    attributeBindings: ["width", "height"],
    init: function init() {
      this._super.apply(this, arguments);

      this.plugins = this.plugins || [];
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var context = this.element;
      var data = this.data;
      var type = this.type;
      var options = this.options;
      var plugins = this.plugins;
      var chart = new Chart(context, {
        type: type,
        data: data,
        options: options,
        plugins: plugins
      });
      this.set("chart", chart);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.chart.destroy();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.updateChart();
    },
    updateChart: function updateChart() {
      var chart = this.chart;
      var data = this.data;
      var options = this.options;
      var animate = this.animate;

      if (chart) {
        chart.data = data;
        chart.options = options;

        if (animate) {
          chart.update();
        } else {
          chart.update(0);
        }

        if (this.customLegendElement) {
          this.customLegendElement.innerHTML = chart.generateLegend();
        }
      }
    }
  });

  _exports.default = _default;
});