define('ember-cropperjs/components/image-cropper', ['exports', 'cropperjs', 'ember-cropperjs/templates/components/image-cropper'], function (exports, _cropperjs, _imageCropper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Properties that do not require a new Cropper instance, rather just need to call
  // a method on the existing instance
  var OPT_UPDATE_METHODS = {
    'aspectRatio': 'setAspectRatio'
  };

  // Properties that require a completely new Cropper instance
  var OPTS_REQUIRE_NEW = ['cropBoxResizable', 'cropBoxMovable'];

  /**
    A component that renders a cropper.
    ```hbs
    {{#image-cropper
      alt='sinbad'
      source='sinbad.jpg'
      options=(hash
        viewMode=2
        width=256
        height=256)}}
  
    {{!-- yielded content --}}
  
    {{/image-cropper}}
    ```
    @class ImageCropper
    @public
  */
  exports.default = Ember.Component.extend({
    classNames: ['image-cropper'],
    layout: _imageCropper.default,

    /**
      The attribute defining the alternative text describing the cropper canvas.
       @argument alt
      @type String
    */
    alt: '',

    /**
      The image source to crop.
       @argument source
      @type String
    */
    source: null,

    /**
      The options to pass down to the Cropper.js instance. Use [Cropper.js options](https://github.com/fengyuanchen/cropperjs#options)
      for reference.
       @argument options
      @type Object
    */
    options: null,

    _cropper: null,
    _prevOptions: null,
    _prevSource: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this._setup);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var _cropper = Ember.get(this, '_cropper');

      if (_cropper === null) {
        return;
      }

      // Check if the image source changed
      if (Ember.compare(Ember.get(this, 'source'), Ember.get(this, '_prevSource')) !== 0) {
        var source = Ember.get(this, 'source');

        _cropper.replace(source);
        Ember.set(this, '_prevSource', source);
      }

      var options = Ember.get(this, 'options');

      if (!options) {
        return;
      }

      // Requires to destroy and re-instantiate a new Cropper instance
      if (OPTS_REQUIRE_NEW.some(function (opt) {
        return Ember.compare(options[opt], _this._prevOptions[opt]) !== 0;
      })) {
        _cropper.destroy();

        var opts = Ember.copy(options);

        Ember.setProperties(this, {
          _prevOptions: opts,
          _cropper: new _cropperjs.default(document.getElementById('image-cropper-' + Ember.get(this, 'elementId')), opts)
        });

        return;
      }

      // Diff the `options` hash for changes
      for (var i = 0; i < Object.keys(OPT_UPDATE_METHODS).length; i++) {
        var opt = Object.keys(OPT_UPDATE_METHODS)[i];

        if (Ember.compare(options[opt], this._prevOptions[opt]) !== 0) {
          _cropper[OPT_UPDATE_METHODS[opt]](options[opt]);
        }
      }

      Ember.set(this, '_prevOptions', Ember.copy(options));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var _cropper = Ember.get(this, '_cropper');
      if (_cropper !== null) {
        _cropper.destroy();
      }
    },
    _setup: function _setup() {
      var image = document.getElementById('image-cropper-' + Ember.get(this, 'elementId'));
      var options = Ember.get(this, 'options');

      // Need a copy because Cropper does not seem to like the Ember EmptyObject that is created from the `{{hash}}` helper
      var opts = Ember.copy(options);

      Ember.setProperties(this, {
        _cropper: new _cropperjs.default(image, opts),
        _prevOptions: Ember.copy(Ember.get(this, 'options')),
        _prevSource: Ember.copy(Ember.get(this, 'source'))
      });
    }
  });
});