define("ember-composable-helpers/helpers/values", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.values = values;

  function values(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        object = _ref2[0];

    if (!object) {
      return object;
    }

    return Object.values(object);
  }

  var _default = Ember.Helper.helper(values);

  _exports.default = _default;
});