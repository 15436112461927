define("ember-composable-helpers/helpers/find-by", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findBy = findBy;

  function findBy(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
        byPath = _ref2[0],
        value = _ref2[1],
        array = _ref2[2];

    if (Ember.isEmpty(byPath)) {
      return [];
    }

    return Ember.A((0, _asArray.default)(array)).findBy(byPath, value);
  }

  var _default = Ember.Helper.helper(findBy);

  _exports.default = _default;
});