define("ember-composable-helpers/helpers/group-by", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.groupBy = groupBy;

  function groupBy(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        byPath = _ref2[0],
        array = _ref2[1];

    var groups = {};
    (0, _asArray.default)(array).forEach(function (item) {
      var groupName = Ember.get(item, byPath);
      var group = groups[groupName];

      if (!Array.isArray(group)) {
        group = [];
        groups[groupName] = group;
      }

      group.push(item);
    });
    return groups;
  }

  var _default = Ember.Helper.helper(groupBy);

  _exports.default = _default;
});