define("ember-composable-helpers/helpers/flatten", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.flatten = flatten;

  function flatten(array) {
    if (!Ember.isArray(array)) {
      return array;
    }

    return (0, _asArray.default)(array).reduce(function (flattened, el) {
      return flattened.concat(flatten(el));
    }, []);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        array = _ref2[0];

    return flatten(array);
  });

  _exports.default = _default;
});