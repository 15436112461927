define("ember-time-field/utils/mod", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mod;
  // wrapping mod
  function mod(n, m) {
    return (n % m + m) % m;
  }
});