define("ember-composable-helpers/helpers/map-by", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mapBy = mapBy;

  function mapBy(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        byPath = _ref2[0],
        array = _ref2[1];

    if (Ember.isEmpty(byPath)) {
      return [];
    }

    return (0, _asArray.default)(array).map(function (item) {
      return Ember.get(item, byPath);
    });
  }

  var _default = Ember.Helper.helper(mapBy);

  _exports.default = _default;
});