define("ember-composable-helpers/helpers/invoke", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.invoke = invoke;
  var all = Ember.RSVP.all;

  function invoke(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        methodName = _ref2[0],
        args = _ref2.slice(1);

    var obj = args.pop();

    if (Ember.isArray(obj)) {
      return function () {
        var promises = obj.map(function (item) {
          var _item$methodName;

          return (_item$methodName = item[methodName]) === null || _item$methodName === void 0 ? void 0 : _item$methodName.call.apply(_item$methodName, [item].concat((0, _toConsumableArray2.default)(args)));
        });
        return all(promises);
      };
    }

    return function () {
      var _obj$methodName;

      return (_obj$methodName = obj[methodName]) === null || _obj$methodName === void 0 ? void 0 : _obj$methodName.call.apply(_obj$methodName, [obj].concat((0, _toConsumableArray2.default)(args)));
    };
  }

  var _default = Ember.Helper.helper(invoke);

  _exports.default = _default;
});