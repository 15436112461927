define("ember-composable-helpers/helpers/object-at", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.objectAt = objectAt;

  function objectAt(index, array) {
    if (!Ember.isArray(array)) {
      return undefined;
    }

    index = parseInt(index, 10);
    return Ember.A(array).objectAt(index);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        index = _ref2[0],
        array = _ref2[1];

    return objectAt(index, array);
  });

  _exports.default = _default;
});