define('ember-aupac-typeahead/components/aupac-ember-data-typeahead', ['exports', 'ember-aupac-typeahead/components/aupac-typeahead'], function (exports, _aupacTypeahead) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isNone = Ember.isNone,
      inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer,
      typeOf = Ember.typeOf;
  exports.default = _aupacTypeahead.default.extend({

    modelClass: null, //@public
    displayKey: 'displayName', //@public
    params: {}, //@public
    async: true, //@public
    queryKey: 'q', //@public

    //private
    store: inject.service('store'),

    /**
     * @Override
     */
    display: computed(function () {
      var _this2 = this;

      return function (model) {
        return model.get(_this2.get('displayKey'));
      };
    }),

    /**
     * @Override
     */
    setValue: function setValue(selection) {
      var _this3 = this;

      if (this.get('_typeahead')) {
        // Was failing in tests with this probably due to a stray observer
        selection = this.transformSelection(selection);
        if (typeof selection === 'string') {
          this.get('_typeahead').typeahead('val', selection);
        } else {
          var displayKey = this.get('displayKey');
          var modelClass = this.get('modelClass');
          if (selection && selection.get('id')) {
            var item = this.get('store').peekRecord(modelClass, selection.get('id'));
            if (isNone(item)) {
              this.get('store').findRecord(modelClass, selection.get('id')).then(function (model) {
                _this3.get('_typeahead').typeahead('val', model.get(displayKey));
              });
            } else {
              this.get('_typeahead').typeahead('val', item.get(displayKey));
            }
          } else {
            this.get('_typeahead').typeahead('val', '');
          }
        }
      }
    },

    /**
     * @Override
     */
    _commitSelection: function _commitSelection() {
      var model = this.get('selection');

      if (this.get('allowFreeInput')) {
        var displayKey = this.get('displayKey');
        var displayValue = typeOf(model) === 'instance' ? model.get(displayKey) : undefined;
        var value = this.get('_typeahead').typeahead('val');

        if (displayValue !== value) {
          this.updateSelectionWhenChanged(value);
        }
      } else if (model) {
        this.setValue(model);
      } else {
        this.setValue(null);
      }
    },

    /**
     * @Override
     */
    init: function init() {
      this._super.apply(this, arguments);

      if (isNone(this.get('modelClass'))) {
        throw new Error('modelClass must be supplied to aupac-typeahead');
      }
    },

    /**
     * @Override
     */
    source: computed(function () {
      var _this = this;
      return function (query, syncResults, asyncResults) {
        var q = {};
        q[_this.get('queryKey')] = query;
        var queryObj = Ember.$.extend(true, {}, q, _this.get('params'));

        _this.get('store').query(_this.get('modelClass'), queryObj).then(function (models) {
          var emberDataModels = [];
          models.get('content').forEach(function (model, i) {
            emberDataModels[i] = model.getRecord();
          });
          asyncResults(emberDataModels);
        });
      };
    }),

    /**
     * @Override
     */
    selectionUpdated: observer('selection.id', '_typeahead', function () {
      var selection = this.get('selection');
      if (isNone(selection)) {
        this.setValue(null);
      } else {
        this.setValue(selection);
      }
    })

  });
});