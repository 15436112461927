define("ember-composable-helpers/helpers/keys", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keys = keys;

  function keys(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        object = _ref2[0];

    if (!object) {
      return object;
    }

    return Object.keys(object);
  }

  var _default = Ember.Helper.helper(keys);

  _exports.default = _default;
});