define("ember-composable-helpers/helpers/filter", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filter = filter;

  function filter(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        callback = _ref2[0],
        array = _ref2[1];

    if (Ember.isEmpty(callback) || !array) {
      return [];
    }

    return (0, _asArray.default)(array).filter(callback);
  }

  var _default = Ember.Helper.helper(filter);

  _exports.default = _default;
});