define('ember-cropperjs/components/image-cropper-call', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  var ImageCropperCallComponent = Ember.Component.extend({
    tagName: '',

    obj: null,
    func: null,
    args: null,
    onResp: null,
    params: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _Ember$getProperties = Ember.getProperties(this, 'obj', 'func', 'args', 'params'),
          obj = _Ember$getProperties.obj,
          func = _Ember$getProperties.func,
          args = _Ember$getProperties.args,
          params = _Ember$getProperties.params;

      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          var _params = _toArray(params);

          func = _params[0];
          args = _params.slice(1);
        }
      }

      (true && !((typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object' && obj !== null) && Ember.assert('image-cropper-call obj is required', (typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) === 'object' && obj !== null));
      (true && !(typeof func === 'string') && Ember.assert('image-cropper-call func is required and must be a string', typeof func === 'string'));
      (true && !(typeof obj[func] === 'function') && Ember.assert('image-cropper-call ' + func + ' must be a function on ' + obj, typeof obj[func] === 'function'));


      this.sendAction('onResp', obj[func].apply(obj, args)); // eslint-disable-line ember/closure-actions
    }
  });

  ImageCropperCallComponent.reopenClass({
    positionalParams: 'params'
  });

  exports.default = ImageCropperCallComponent;
});