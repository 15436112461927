define("ember-time-field/states/manager", ["exports", "ember-time-field/utils/state-manager", "ember-time-field/utils/state", "ember-time-field/states/unfocused", "ember-time-field/states/focused"], function (exports, _stateManager, _state, _unfocused, _focused) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _stateManager.default.extend({
    initialState: 'unfocused',
    unfocused: (0, _state.state)(_unfocused.default),
    focused: (0, _state.state)(_focused.default)
  });
});