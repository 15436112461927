define("ember-composable-helpers/helpers/pick", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pick = pick;

  function pick(_ref
  /*, hash*/
  ) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        path = _ref2[0],
        action = _ref2[1];

    return function (event) {
      var value = Ember.get(event, path);

      if (!action) {
        return value;
      }

      action(value);
    };
  }

  var _default = Ember.Helper.helper(pick);

  _exports.default = _default;
});