define('ember-print-this/components/print-this', ['exports', 'ember-print-this/templates/components/print-this'], function (exports, _printThis) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _printThis.default,
    printThis: Ember.inject.service(),
    defaultPrintClass: 'content__printThis',
    classNameBindings: ['defaultPrintClass'],
    printSelector: null,
    autoPrint: false,
    options: null,
    actions: {
      doPrint: function doPrint() {
        this._print();
      }
    },

    didInsertElement: function didInsertElement() {
      if (this.get('autoPrint')) {
        this._print();
      }
    },
    _print: function _print() {
      var printSelector = this.get('printSelector') || '';

      this.get('printThis').print(printSelector, this.get('options') || {}, this.$.bind(this));
    }
  });
});