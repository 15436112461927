define("ember-composable-helpers/helpers/slice", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray", "ember-composable-helpers/utils/as-array"], function (_exports, _toConsumableArray2, _toArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slice = slice;

  function slice(_ref) {
    var _array;

    var _ref2 = (0, _toArray2.default)(_ref),
        args = _ref2.slice(0);

    var array = args.pop();
    array = (0, _asArray.default)(array);
    return (_array = array).slice.apply(_array, (0, _toConsumableArray2.default)(args));
  }

  var _default = Ember.Helper.helper(slice);

  _exports.default = _default;
});