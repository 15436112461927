define('ember-cropperjs/components/image-cropper-on', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
    A component used for responding to cropper events. Typically used via the image-cropper
    contextual component with positional parameters like so:
  
    ```hbs
    {{#image-cropper
      alt='sinbad'
      source='sinbad.jpg'
      options=(hash
        viewMode=2
        width=256
        height=256)
    as |cropper|}}
  
    {{cropper.on 'crop' (action 'cropImage')}}
  
    {{/image-cropper}}
    ```
    @class ImageCropperOn
    @public
  */
  var ImageCropperOnComponent = Ember.Component.extend({
    tagName: '',

    cropper: null,

    eventSource: null,
    /**
      The [event from Cropper.js](https://github.com/fengyuanchen/cropperjs#events) to listen for.
       @argument event
      @type String
    */
    event: null,

    /**
      The action to call when the event is triggered.
       @argument action
      @type Action
    */
    action: null,

    _prevEvent: null,

    init: function init() {
      this._super.apply(this, arguments);

      this._boundOnEvent = Ember.run.bind(this, this._onEvent);
    },


    _normalizedEvent: Ember.computed('event', function () {
      var event = Ember.get(this, 'event');
      (true && !(typeof event === 'string') && Ember.assert('image-cropper-event requires event to be a string, was ' + event, typeof event === 'string'));


      return event.toLowerCase();
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _Ember$getProperties = Ember.getProperties(this, 'eventSource', '_normalizedEvent', '_prevEvent', 'action'),
          eventSource = _Ember$getProperties.eventSource,
          _normalizedEvent = _Ember$getProperties._normalizedEvent,
          _prevEvent = _Ember$getProperties._prevEvent,
          action = _Ember$getProperties.action;

      (true && !(Ember.isPresent(eventSource)) && Ember.assert('image-cropper-event requires an eventSource', Ember.isPresent(eventSource)));
      (true && !(Ember.isPresent(action)) && Ember.assert('image-cropper-event requires an action', Ember.isPresent(action)));


      if (_normalizedEvent !== _prevEvent) {
        if (_prevEvent) {
          eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
        }

        this._prevEvent = _normalizedEvent;
        eventSource.addEventListener(_normalizedEvent, this._boundOnEvent);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var _Ember$getProperties2 = Ember.getProperties(this, 'eventSource', '_prevEvent'),
          eventSource = _Ember$getProperties2.eventSource,
          _prevEvent = _Ember$getProperties2._prevEvent;

      if (eventSource && _prevEvent) {
        eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
      }
    },
    _onEvent: function _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var action = this.action;


      if (action) {
        action.apply(undefined, [this.cropper].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  ImageCropperOnComponent.reopenClass({
    positionalParams: ['event', 'action']
  });

  exports.default = ImageCropperOnComponent;
});