define("ember-composable-helpers/helpers/compute", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toConsumableArray2, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compute = compute;
  _exports.default = void 0;

  function compute(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        action = _ref2[0],
        params = _ref2.slice(1);

    return action.apply(void 0, (0, _toConsumableArray2.default)(params));
  }

  var _default = Ember.Helper.helper(compute);

  _exports.default = _default;
});