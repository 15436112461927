define("ember-composable-helpers/helpers/without", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.without = without;

  function contains(needle, haystack) {
    return Ember.A(haystack).includes(needle);
  }

  function without(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }

    if (Ember.isArray(needle) && needle.length) {
      return haystack.reduce(function (acc, val) {
        return contains(val, needle) ? acc : acc.concat(val);
      }, []);
    }

    return Ember.A(haystack).without(needle);
  }

  var _default = Ember.Helper.helper(function (_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        needle = _ref2[0],
        haystack = _ref2[1];

    return without(needle, haystack);
  });

  _exports.default = _default;
});