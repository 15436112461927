define('ember-bootstrap/utils/overrideable-cp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = overrideableCP;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /**
   * CP macro that created a regular computed property, which can be manually overriden.
   * This is needed after implicitly overrideable CPs have been deprecated:
   * https://deprecations-app-prod.herokuapp.com/deprecations/v3.x/#toc_computed-property-override
   *
   * @private
   */
  function overrideableCP() {
    var _Ember;

    var fn = Array.prototype.slice.call(arguments, -1)[0];
    var args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
    (true && !(typeof fn === 'function') && Ember.assert('Last argument for overrideableCP must be a function', typeof fn === 'function'));


    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(args).concat([{
      get: function get(key) {
        var overridden = this['__' + key];
        return overridden || fn.call(this);
      },
      set: function set(key, value) {
        this['__' + key] = value;
        return value;
      }
    }]));
  }
});