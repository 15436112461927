define("ember-composable-helpers/helpers/repeat", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.repeat = repeat;

  function repeat(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        length = _ref2[0],
        value = _ref2[1];

    if (Ember.typeOf(length) !== 'number') {
      return [value];
    }

    return Array.apply(null, {
      length: length
    }).map(function () {
      return value;
    }); // eslint-disable-line
  }

  var _default = Ember.Helper.helper(repeat);

  _exports.default = _default;
});