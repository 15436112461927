define("ember-time-field/states/unfocused", ["exports", "ember-time-field/utils/state"], function (exports, _state) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _state.default.extend({
    focusIn: function focusIn(manager) {
      manager.transitionTo("focused.hours");
    },


    // it's possible to receive events after losing focus, due to runloop shenanigans
    // just swallow them as no-ops
    refocus: function refocus() {},
    focusOut: function focusOut() {},
    up: function up() {},
    down: function down() {},
    left: function left() {},
    right: function right() {},
    key: function key() {}
  });
});