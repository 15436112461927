define('ember-time-field/utils/state-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Object.extend({

    initialState: null,
    currentState: Ember.computed({
      get: function get() {
        var name = this.get('initialState');
        return this.get(name);
      },
      set: function set(_key, val) {
        return val;
      }
    }),

    transitionTo: function transitionTo(path) {
      var _this = this;

      var current = this.get('currentState');
      while (current) {
        var nextState = current.get(path);
        if (nextState) {
          allBetween(current, nextState).forEach(function (state) {
            return state.enter && state.enter(_this);
          });
          this.set("currentState", nextState);
          return;
        }
        current = current.get('parent');
      }

      throw new Error('Can\'t transition to ' + path);
    },
    send: function send(name) {
      var state = this.get('currentState');

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key2 = 1; _key2 < _len; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return state.send.apply(state, [name].concat(_toConsumableArray(args)));
    }
  });


  function allBetween(start, end) {
    var parent = end.get('parent');
    var intermediate = [end];

    while (parent) {
      intermediate.push(parent);
      parent = parent.get('parent');
      if (parent === start) {
        break;
      }
    }

    return intermediate;
  }
});