define("ember-composable-helpers/helpers/drop", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-composable-helpers/utils/as-array"], function (_exports, _slicedToArray2, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.drop = drop;

  function drop(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        dropAmount = _ref2[0],
        array = _ref2[1];

    return (0, _asArray.default)(array).slice(dropAmount);
  }

  var _default = Ember.Helper.helper(drop);

  _exports.default = _default;
});