define('ember-time-field/utils/state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.state = state;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function state(klass) {
    var attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return Ember.computed({
      get: function get() {
        var manager = this.get('manager') || this;
        var parent = this;
        return klass.create(Object.assign(attrs, { manager: manager, parent: parent }));
      }
    });
  }

  exports.default = Ember.Object.extend({
    send: function send(name) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (this[name]) {
        this[name].apply(this, [this.get('manager')].concat(_toConsumableArray(args)));
      } else if (this.get('parent')) {
        var _get;

        (_get = this.get('parent')).send.apply(_get, [name].concat(_toConsumableArray(args)));
      } else {
        throw new Error('Unhandled event ' + name);
      }
    }
  });
});